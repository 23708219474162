<template>
  <div>
    <Hearder></Hearder>
    <AppMain></AppMain>
    <Footer v-if="$route.path !== '/person/chat/personChat'"></Footer>
    <Suspension></Suspension>
    <div v-show="!isCloase" class="hidden-xs-only" style="z-index: 101578; left: 20px; position: fixed; bottom: 10%; height: 234px">
      <img src="../assets/image/45.gif" width="100" height="14" border="0" vspace="3" alt="关闭广告" @click="isCloase = true" />
      <br />
      <img v-if="showlibao" src="https://www.ayrcgw.com/images/1737701326485.gif" width="50%" border="0" alt="新春大礼包" @click="libaoJump" />
      <img v-else src="https://www.hprcw.net/assets/medium/weixin.jpg" width="165" height="185" border="0" />
    </div>
    <!-- 活动图片格式弹窗 -->
    <div v-if="guanggao" class="mask"></div>
    <div v-if="guanggao" id="imgDiv" style="position: fixed; left: 41%; bottom: 40%; top: 25%; z-index: 9999; text-align: center">
      <img :src="imgNotice" style="cursor: pointer; border-radius: 15px" @click="openUrl" />
      <div class="caozuo">
        <div class="imgClose" @click="close">关闭</div>
        <div class="imgClose" @click="close1">不再提示</div>
        <div v-if="openPage" class="imgClose" @click="openUrl">了解详情</div>
      </div>
    </div>
  </div>
</template>

<script>
import intro from '@/utils/introStep.js';
import { login } from '@/utils/js/im.js';
import { getTime } from '@/utils/validate';
export default {
  components: {
    Hearder: () => import('./components/Hearder'),
    AppMain: () => import('./components/AppMain'),
    Footer: () => import('./components/Footer'),
    Suspension: () => import('./components/Suspension')
  },
  data() {
    return {
      isCloase: false,
      guanggao: false,
      showlibao: false,
      utype: localStorage.getItem('utype'),
      tipsImg1: 'http://mp.ayrcgw.com/lanwechat/ewm.php?uid=',
      endTIme: '2024-01-28 12:00:00',
      imgNotice: 'https://www.ayrcgw.com/images/989.png',
      openPage: 'https://mp.weixin.qq.com/s/Eq-IE1nGm5QNUwkeO8x4AQ',
      storageKey: 'guokao_2' //2是求职者
    };
  },
  created() {
    let _this = this;
    if (localStorage.getItem('uid')) {
      _this.login();
      _this.getMessage();
      _this.eventBus.$on('eventBusName2', function (data) {
        _this.getMessage();
      });
    }
  },
  mounted() {
    const that = this;
    let isTrueTime = new Date().getTime() >= new Date('2025-01-29 00:00:00').getTime() && new Date().getTime() <= new Date('2025-02-28 00:00:00').getTime();
    this.showlibao = (!localStorage.getItem('uid') || localStorage.getItem('utype') == '1') && isTrueTime;
    // let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //判断是否是 iOS终端
    // // 上架广告，修改以上四个变量就行
    // if (this.$route.path == '/') {
    //   this.guanggao = localStorage.getItem(this.storageKey) ? localStorage.getItem(this.storageKey) === '1' : true;
    //   if (isIOS) {
    //     this.guanggao = !(new Date(getTime().replace(/-/g, '/')) > new Date(this.endTIme.replace(/-/g, '/')));
    //   } else {
    //     this.guanggao = !(new Date(getTime()) > new Date(this.endTIme));
    //   }
    // }

    setTimeout(function () {
      if (localStorage.getItem('isFirst_2') != '2' && localStorage.getItem('uid') && localStorage.getItem('utype') == '2') {
        that.initGuide();
      }
    }, 100);
  },
  methods: {
    initGuide() {
      intro
        .setOptions({
          steps: [
            {
              title: '下拉菜单',
              element: '#dropdown_2',
              intro: `鼠标悬浮在该模块上，可快速进入【个人中心】、【隐私设置】等功能页面中。`
            },
            {
              title: '在线反馈',
              element: '#header_2_2',
              intro: '点击此处可以反馈下您的建议和问题，我们收到后会第一时间处理。'
            },
            {
              title: '在线简历',
              element: '#header_2_1',
              intro:
                `点击这里，填写你自己的牛人简历，如果已创建简历，每当编辑/保存操作都会重新提交审核，关注下方订阅号，不错过重要信息哦。<div style="text-align:center;margin-top:15px;"><img src="` +
                this.tipsImg1 +
                localStorage.getItem('uid') +
                `" style="width: 130px;height: 130px;" /></div>`
            },
            {
              title: '在线直聊',
              element: '#header_2_0',
              intro: '点击此处可进入聊天列表页面。'
            }
          ]
        })
        .onbeforeexit(() => {
          //// 确认完毕之后执行的事件
          localStorage.setItem('isFirst_2', '2');
        })
        .start();
    },
    libaoJump() {
      if (!localStorage.getItem('uid')) {
        this.$router.push('/user/login');
        return;
      }
      this.$router.push('/company/account/setmeal');
    },
    openUrl() {
      //   this.guanggao = false;
      window.open(this.openPage);
    },
    close() {
      this.guanggao = false;
    },
    close1() {
      this.guanggao = false;
      localStorage.setItem(this.storageKey, '0');
    },
    getMessage() {
      let _data = {
        method: 'list',
        utype: localStorage.getItem('utype'),
        uid: localStorage.getItem('uid')
      };
      this.$http.post('/msg_online', _data, false).then((res) => {
        let _num = 0;
        let _dataList = res.msg || [];
        _dataList.forEach((val) => {
          if (val.utype !== localStorage.getItem('utype')) {
            _num += parseInt(val.unreade);
          }
        });
        this.$store.commit('setMsg', _num);
      });
    }
  }
};
</script>

<style>
.mask {
  background-color: rgb(0, 0, 0);
  opacity: 0.3;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
}

.imgClose {
  width: fit-content;
  background-color: rgb(255, 255, 255);
  text-align: center;
  cursor: pointer;
  color: rgb(120, 74, 48);
  border-radius: 5px;
  border: 1px solid;
  padding: 3px 9px;
  font-size: 13px;
}

.caozuo {
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
</style>
